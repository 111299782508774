import { Elm } from '../elm/src/HomeTable.elm'

document.addEventListener('DOMContentLoaded', (event) => {
  const app = Elm.HomeTable.init({
    node: document.getElementById('elm'),
    flags: window.ElmFlags.HomeTable
  })

  app.ports.pushUrl.subscribe(url => {
    history.replaceState({}, '', url)
  })

  app.ports.initFailed.subscribe(message => {
    console.log(message)
    const err = new Error(message)
    err.name = 'Elm.HomeTable.Init error'
    window.onerror(err.name, window.location.href, 0, 0, err)
  })

  app.ports.logError.subscribe(message => {
    console.log(message)
  })
})
